import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import FileTransfers from "./components/fileTransfer/index";
import EIConnections from "./components/eiConnection";
import FileCommands from "./components/fileTransferCommand";
import OuraPatients from "./components/ouraPatient";
import APIConfigs from "./components/apiConfig";
import ServiceConfigs from "./components/serviceConfig";
import TaskSchedules from "./components/taskSchedule";
import QueryScheduleTask from "./components/queryScheduleTask";
import EICaches from "./components/eiCache";
import RemarqueEvents from "./components/remarqueEvents";
import EIConnectionForm from "./components/eiConnection/eiConnectionForm";
import ConnectionCommands from "./components/connectionCommand";
import ConnectionCommandForm from "./components/connectionCommand/connectionCommandForm";
import FileTransferCommandForm from "./components/fileTransferCommand/fileTransferCommandForm";
import FileTransferForm from "./components/fileTransfer/fileTransferForm";
import OuraPatientForm from "./components/ouraPatient/ouraPatientForm";
import ServiceConfigForm from "./components/serviceConfig/serviceConfigForm";
import Login from "./components/login/login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import TopBar from "./components/topBar";
import { DataViewProvider } from "./contexts/data.view.context";
import APIConfigForm from "./components/apiConfig/apiConfigForm";
import TaskScheduleForm from "./components/taskSchedule/taskScheduleForm";
import QueryScheduleTaskForm from "./components/queryScheduleTask/queryScheduleTaskForm";
import FileTrnsStatsViewByTrnsID from "./components/fileTransferStats/transferID";
import FileTrnsStatsViewByGroup from "./components/fileTransferStats/group";
import FileTrnsStatsViewByProject from "./components/fileTransferStats/projectID";
import ErrorLogReport from "./components/reports/errorLogReport";
import EventsReport from "./components/reports/eventsReport";
import ReceiveDataReport from "./components/reports/recieveDataReport";
import QueryInfo from "./components/queryInfo";
import FileTransferHistoryView from "./components/fileTransferHistory";
import RedHatProcess from "./components/redHatProcess";

const theme = createTheme();

function App() {
  const [isSignedIn, setIsSignedIn] = useState(null);

  useEffect(() => {
    setIsSignedIn(sessionStorage.getItem("access_token") ? true : false);
  }, []);

  if (!isSignedIn) {
    return <Login setIsSignedIn={setIsSignedIn} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <DataViewProvider>
        <Router>
          <TopBar isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />
          <Routes>
            <Route
              path="/Login"
              element={<Login setIsSignedIn={setIsSignedIn} />}
            />
            <Route path="/" element={<Login setIsSignedIn={setIsSignedIn} />} />
            <Route
              path="/FileTransferHistoryView"
              element={<FileTransferHistoryView />}
            />
            <Route
              path="/FileTrnsStatsViewByTrnsID"
              element={<FileTrnsStatsViewByTrnsID />}
            />
            <Route path="/FileTransfers" element={<FileTransfers />} />
            <Route path="/EIConnection" element={<EIConnections />} />
            <Route path="/OuraPatients" element={<OuraPatients />} />
            <Route path="/APIConfigs" element={<APIConfigs />} />
            <Route path="/ServiceConfigs" element={<ServiceConfigs />} />
            <Route path="/TaskSchedules" element={<TaskSchedules />} />
            <Route path="/EICaches" element={<EICaches />} />
            <Route path="/RemarqueEvents" element={<RemarqueEvents />} />
            <Route path="/QueryInfo" element={<QueryInfo />} />

            <Route
              path="/FileTrnsStatsViewByGroup"
              element={<FileTrnsStatsViewByGroup />}
            />
            <Route
              path="/FileTrnsStatsViewByProject"
              element={<FileTrnsStatsViewByProject />}
            />
            <Route path="/ErrorLogReport" element={<ErrorLogReport />} />
            <Route path="/EventsReport" element={<EventsReport />} />
            <Route path="/RecieveDataReport" element={<ReceiveDataReport />} />
            <Route path="/FileCommands">
              <Route path=":transferID" element={<FileCommands />} />
            </Route>
            <Route path="/ConnectionCommands">
              <Route path=":connectionID" element={<ConnectionCommands />} />
            </Route>
            <Route path="/QueryScheduleTasks">
              <Route path=":schedule_id" element={<QueryScheduleTask />} />
            </Route>
            <Route path="/AddEIConnection" element={<EIConnectionForm />} />
            <Route
              path="/AddTransferCmd"
              element={<FileTransferCommandForm />}
            />
            <Route
              path="/AddConnectionCmd"
              element={<ConnectionCommandForm />}
            />
            <Route path="/AddOuraPatient" element={<OuraPatientForm />} />
            <Route path="/AddFileTransfer" element={<FileTransferForm />} />
            <Route path="/AddServiceConfig" element={<ServiceConfigForm />} />
            <Route path="/AddAPIConfig" element={<APIConfigForm />} />
            <Route path="/AddQuerySchedule" element={<TaskScheduleForm />} />
            <Route
              path="/AddQueryScheduleTask"
              element={<QueryScheduleTaskForm />}
            />
            <Route path="/RedHatProcess" element={<RedHatProcess />} />
          </Routes>
          <NotificationContainer />
        </Router>
      </DataViewProvider>
    </ThemeProvider>
  );
}

export default App;
