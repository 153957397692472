const access_token = sessionStorage.getItem("access_token");
const { REACT_APP_API_URL = "https://esbdev.premier-research.com/" } =
  process.env;

const { REACT_APP_API_URL_DEV = "https://esbdev.premier-research.com/" } =
  process.env;

const { REACT_APP_API_URL_TEST = "https://esbdev.premier-research.com/" } =
  process.env;

const { REACT_APP_API_URL_PROD = "https://esbdev.premier-research.com/" } =
  process.env;

export function GetDataFromAPI(resourcePath) {
  console.log("GET_DATA_FROM_API", resourcePath);
  let headers = new Headers();

  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  return fetch(URL, {
    method: "GET",
    headers: headers,
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return [];
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export function GetDataFromAPIwithEnv(resourcePath, environment) {
  console.log("GetDataFromAPIwithEnv = ", resourcePath, " , ", environment);
  let headers = new Headers();
  let URL = REACT_APP_API_URL + resourcePath;

  headers.append("Authorization", "Bearer " + access_token);

  if (environment === "dev") {
    URL = REACT_APP_API_URL_DEV + resourcePath;
  } else if (environment === "test") {
    URL = REACT_APP_API_URL_TEST + resourcePath;
  } else if (environment === "prod") {
    URL = REACT_APP_API_URL_PROD + resourcePath;
  } else {
  }

  return fetch(URL, {
    method: "GET",
    headers: headers,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function GetDataFromAllEnv(resourcePath) {
  let headers = new Headers();
  let URL_DEV = REACT_APP_API_URL_DEV + resourcePath;
  let URL_TEST = REACT_APP_API_URL_TEST + resourcePath;
  let URL_PROD = REACT_APP_API_URL_PROD + resourcePath;

  headers.append("Authorization", "Bearer " + access_token);

  const responses = await Promise.all([
    fetch(URL_DEV, {
      method: "GET",
      headers: headers,
    }),
    fetch(URL_TEST, {
      method: "GET",
      headers: headers,
    }),
    fetch(URL_PROD, {
      method: "GET",
      headers: headers,
    }),
  ]);

  const dev_data = await responses[0].json();
  const test_data = await responses[1].json();
  const prod_data = await responses[2].json();

  const data = [dev_data, test_data, prod_data];

  return data;
}

export function AddOuraPatient(resourcePath, data) {
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data, null, 2),
  };
  return fetch(URL, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (response.status === 200) {
        return {
          code: 200,
          title: "Result",
          message: "Record Added Successfully!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}

export function AddRecord(resourcePath, data) {
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data, null, 2),
  };
  return fetch(URL, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      console.log(URL, " = ", data);
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (data.returnCode === 200 || response.status === 200) {
        return {
          code: 200,
          title: "Result",
          message: "Record Added Successfully!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}

export function AddRecordToOtherEnv(resourcePath, env, data) {
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + access_token);

  let URL = REACT_APP_API_URL + resourcePath;

  if (env === "Dev") {
    URL = REACT_APP_API_URL_DEV + resourcePath;
  } else if (env === "Test") {
    URL = REACT_APP_API_URL_TEST + resourcePath;
  } else if (env === "Prod") {
    URL = REACT_APP_API_URL_PROD + resourcePath;
  } else {
    URL = REACT_APP_API_URL + resourcePath;
  }

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data, null, 2),
  };
  return fetch(URL, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      console.log(URL, " = ", data);
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (data.returnCode === 200 || response.status === 200) {
        return {
          code: 200,
          title: "Result",
          message: "Record Added Successfully!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}

export function UpdateRecord(resourcePath, data) {
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data, null, 2),
  };
  return fetch(URL, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (data.returnCode === 200 || response.ok) {
        return {
          code: 200,
          title: "Result",
          message: "Record Updated Successfully!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}

export function UpdateOuraPatient(resourcePath, data) {
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  const requestOptions = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data, null, 2),
  };
  return fetch(URL, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (response.status === 200) {
        return {
          code: 200,
          title: "Result",
          message: "Record Updated Successfully!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}

export function ClearCache(resourcePath) {
  let headers = new Headers();

  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  return fetch(URL, {
    method: "GET",
    headers: headers,
  })
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (response.status === 200) {
        return {
          code: 200,
          title: "Result",
          message: "Refresh Successfull!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}

export function GetCacheListEI(resourcePath) {
  let headers = new Headers();

  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  return fetch(URL, {
    method: "GET",
    headers: headers,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.error(err);
    });
}

export function ProcessRemarqueEvents(resourcePath) {
  let headers = new Headers();

  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;

  return fetch(URL, {
    method: "GET",
    headers: headers,
  })
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (response.status === 200) {
        return {
          code: 200,
          title: "Result",
          message: "Reprocess Successfully!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}

export function RunRedHatProcess(resourcePath, data) {
  console.log("RunRedHatProcess = ", data);
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + access_token);

  const URL = REACT_APP_API_URL + resourcePath;
  //const URL = "http://localhost:3000" + resourcePath;

  console.log("RunRedHatProcess URL = ", URL);

  console.log("RunRedHatProcess = ", data);

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data, null, 2),
  };
  return fetch(URL, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      if (response.status === 200) {
        return {
          code: 200,
          title: "Result",
          message: "Record Added Successfully!",
        };
      } else {
        return {
          code: 500,
          title: "Error",
          message: data.returnMessage,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return error.toString();
    });
}
