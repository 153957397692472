import React from "react";
import { MaterialReactTable } from "material-react-table";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { Box, TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Confirm } from "react-admin";
import { useState } from "react";

const RemarqueEventsViewTable = ({ data, columns, subRowData }) => {
  const validationSchema = Yup.object().shape({
    EVENT_TYPE: Yup.string().required("Event Type is required"),
    START_DATE: Yup.string().nullable().notRequired().default(""),
    END_DATE: Yup.string().nullable().notRequired().default(""),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState([]);

  const eventTypes = [
    "ActionItem",
    "CommunicationLog",
    "Institution",
    "Investigator",
    "Manual",
    "PatientMonitoring",
    "ProtocolDeviation",
    "Site",
    "SiteReports",
    "SiteActionActivityFeed",
    "SiteVisit",
    "StudyContact",
    "Vendor",
    "All",
  ];

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours("0");
  yesterday.setMinutes("0");
  yesterday.setSeconds("0");

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours("0");
  tomorrow.setMinutes("0");
  tomorrow.setSeconds("0");

  const onSubmit = () => {
    const data = formData;
    console.log(data);

    if (data.START_DATE === "") {
      data.START_DATE = yesterday;
    }

    data.START_DATE = dayjs(data.START_DATE).format("YYYYMMDDTHHmmss");

    if (data.END_DATE === "") {
      data.END_DATE = tomorrow;
    }

    data.END_DATE = dayjs(data.END_DATE).format("YYYYMMDDTHHmmss");
    const resourcePath =
      "integrations/remarque/getevents/" +
      data.EVENT_TYPE +
      "/start/" +
      data.START_DATE +
      "/end/" +
      data.END_DATE;

    API.ProcessRemarqueEvents(resourcePath).then((response) => {
      if (response.code === 200) {
        NotificationManager.success(
          "Successful!",
          "Remarque Processing",
          10000
        );
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
        reset();
      }
    });
  };

  const handleClick = (data) => {
    setFormData(data);
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    onSubmit();
    setOpen(false);
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      initialState={{
        density: "compact",
      }}
      renderTopToolbarCustomActions={() => (
        <>
          <form>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
                display: "flex",
                flexWrap: "wrap",
              }}
              align="left"
              justifyContent="space-between"
            >
              <Controller
                control={control}
                name="EVENT_TYPE"
                id="EVENT_TYPE"
                render={({
                  field: { ref, onBlur, name, margin, size, variant, ...field },
                  fieldState,
                }) => (
                  <TextField
                    select
                    label="Event Type"
                    id="EVENT_TYPE"
                    margin="dense"
                    size="small"
                    {...register("EVENT_TYPE")}
                    error={errors.EVENT_TYPE ? true : false}
                    variant="outlined"
                    defaultValue="All"
                  >
                    {eventTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  control={control}
                  name="START_DATE"
                  id="START_DATE"
                  render={({
                    field: {
                      ref,
                      onBlur,
                      name,
                      margin,
                      size,
                      variant,
                      ...field
                    },
                    fieldState,
                  }) => (
                    <DateTimePicker
                      {...field}
                      inputRef={ref}
                      label="START_DATE"
                      id="START_DATE"
                      defaultValue={yesterday}
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "START_DATE",
                          margin: "dense",
                          variant: "outlined",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  control={control}
                  name="END_DATE"
                  id="END_DATE"
                  render={({
                    field: {
                      ref,
                      onBlur,
                      name,
                      margin,
                      size,
                      variant,
                      ...field
                    },
                    fieldState,
                  }) => (
                    <DateTimePicker
                      {...field}
                      inputRef={ref}
                      label="END_DATE"
                      id="END_DATE"
                      defaultValue={tomorrow}
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "END_DATE",
                          margin: "dense",
                          variant: "outlined",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                variant="contained"
                onClick={handleSubmit(handleClick)}
                sx={{
                  size: "small",
                  height: "40px",
                  top: "8px",
                  left: "20px",
                }}
              >
                {"REQUEST"}
              </Button>
            </Box>

            <Confirm
              isOpen={open}
              title={``}
              content="Are you sure you want to reprocess?"
              onConfirm={handleConfirm}
              onClose={handleDialogClose}
              cancel={`Cancel`}
              confirm={`Confirm`}
            />
          </form>
        </>
      )}
      renderDetailPanel={({ row }) => {
        const mainEventType = row.original.EVENT_TYPE;

        let rowData = null;

        rowData = subRowData.map((remarqueEvent) => {
          if (mainEventType === remarqueEvent.EVENT_TYPE) {
            return {
              EVENT_TYPE: remarqueEvent.EVENT_TYPE,
              ACCESS_TS: remarqueEvent.ACCESS_TS,
              ACCESS_STATUS: remarqueEvent.ACCESS_STATUS,
              ACCESS_DETAILS: remarqueEvent.ACCESS_DETAILS,
              ACCESS_METHOD: remarqueEvent.ACCESS_METHOD,
              ACCESS_URL: remarqueEvent.ACCESS_URL,
              EVENT_START: remarqueEvent.EVENT_START,
              EVENT_END: remarqueEvent.EVENT_END,
              EVENT_DURATION: remarqueEvent.EVENT_DURATION,
              EVENT_MESSAGE: remarqueEvent.EVENT_MESSAGE,
            };
          } else {
            return null;
          }
        });

        if (rowData != null) {
          rowData = rowData.filter(function (x) {
            return x !== null;
          });
        }

        return (
          <MaterialReactTable
            columns={columns}
            data={rowData}
            initialState={{
              density: "compact",
            }}
            enableFilters={false}
            enableDensityToggle={false}
            enableFiltersToggle={false}
            enableSortingRemoval={false}
            enableshowFilter={false}
            enableFullScreenToggle={false}
            enableColumnFilters={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enableColumnActions={false}
          />
        );
      }}
      autoResetPageIndex={false}
      enableFilters={false}
      enableSorting={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableColumnActions={false}
      enablePagination={false}
      enableBottomToolbar={false}
    />
  );
};

export default RemarqueEventsViewTable;
