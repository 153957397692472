import React, { useEffect } from "react";

import RedHatProcessViewTable from "./redhatProcessView";
import "../util/tables.css";
import { useDataView } from "../../contexts/data.view.context";

const columns = [
  {
    accessorKey: "TYPE",
    header: "Type",
    id: "TYPE",
    size: 75,
  },
  {
    accessorKey: "STATUS",
    header: "STATUS",
    id: "STATUS",
    size: 50,
  },

  {
    accessorKey: "CMD",
    header: "Cmd",
    id: "CMD",
    size: 75,
  },

  {
    accessorKey: "CREATED_TS",
    header: "CREATED_TS",
    id: "CREATED_TS",
    size: 100,
  },
  {
    accessorKey: "COMPLETE_TS",
    header: "Duration",
    id: "COMPLETE_TS",
    size: 75,
  },
  {
    accessorKey: "REQUEST_DATA",
    header: "REQUEST_DATA",
    id: "REQUEST_DATA",
    size: 75,
  },

  {
    accessorKey: "RESULT_DATA",
    header: "RESULT_DATA",
    id: "RESULT_DATA",
    size: 75,
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const RedHatProcess = () => {
  const {
    fetchRemoteExecEvents,
    remoteExecEvents,
    fetchRemoteProcessList,
    remoteProcessList,
  } = useDataView();

  const fetchData = async () => {
    if (remoteExecEvents.length === 0) await fetchRemoteExecEvents();
    if (remoteProcessList.length === 0) await fetchRemoteProcessList();
    //console.log(remoteProcessList);
  };

  /*
  useEffect(() => {
    (async () => {
      if (remoteExecEvents.length === 0) await fetchRemoteExecEvents();
    })();
    // eslint-disable-next-line
  }, []);
  */

  useEffect(() => {
    fetchData(); // Fetch data initially

    const intervalId = setInterval(() => {
      fetchRemoteExecEvents();
    }, 30000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative">
      <h2 style={h2Style}>Red Hat Tasks</h2>

      {remoteExecEvents.length > 0 && (
        <RedHatProcessViewTable
          data={remoteExecEvents.map((remoteTask) => {
            return {
              TYPE: remoteTask.TYPE,
              STATUS: remoteTask.STATUS,
              CMD: remoteTask.CMD,
              CREATED_TS: remoteTask.CREATED_TS,
              COMPLETE_TS: remoteTask.COMPLETE_TS,
              REQUEST_DATA: remoteTask.REQUEST_DATA,
              RESULT_DATA: remoteTask.RESULT_DATA,
            };
          })}
          columns={columns}
          processList={remoteProcessList}
        />
      )}
    </div>
  );
};

export default RedHatProcess;
