import React, { useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import { Confirm } from "react-admin";
import { useState } from "react";
import { useDataView } from "../../contexts/data.view.context";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Stack } from "@mui/material";

const RedHatProcessViewTable = ({ data, columns, processList }) => {
  const { fetchRemoteExecEvents } = useDataView();
  const validationSchema = Yup.object().shape({
    Task_Name: Yup.string().required("Select Process"),
  });

  const { reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      Task_Name: "",
      Env: "",
      Args: null,
    },
  });

  const [open, setOpen] = useState(false);

  let processTypes = [];

  /*
  "Smart Sheet Upload",
    "Smart Sheet Download",
    "WSO2 Deployer",
    "Git Checkout",
    "Git Build",
  */

  let taskTypes = [];

  /*
  {
      taskName: "Git Build",
      script: "esbbuild",
      cmdDir: "/home/svc_esb_datalab/Projects/esb/Java",
      params: "",
    },
    {
      taskName: "Git Checkout",
      script: "git",
      cmdDir: "/home/svc_esb_datalab/Projects/esb",
      params: ["pull"],
    },
    {
      taskName: "Smart Sheet Download",
      script: "SmartSheetReports.sh",
      cmdDir: "/home/svc_esb_datalab/Projects/esb/Java",
      params: "",
    },
    {
      taskName: "Smart Sheet Upload",
      script: "SmartSheetData.sh",
      cmdDir: "/home/svc_esb_datalab/Projects/esb/Java",
      params: "",
    },
    {
      taskName: "WSO2 Deployer",
      script: "WSO2Deployer.sh",
      cmdDir: "/home/svc_esb_datalab/Projects/esb/Java",
      params: ["-car=true", "-jar=cnci", "-jar=com.prg"],
    },
  */

  processList.map((remoteProcess) => {
    const jsonObject = JSON.parse(remoteProcess.PropertyValue);
    const taskobj = {
      taskName: jsonObject.name,
      script: remoteProcess.ServiceName,
      cmdDir: jsonObject.cmdDir,
      params: jsonObject.params,
    };

    taskTypes.push(taskobj);
    processTypes.push(jsonObject.name);
  });

  const environments = ["DEV", "TEST", "PROD"];

  const onSubmit = () => {
    let taskType = "CMD";
    let cmdDir = "~/Projects/esb/Java/";
    let outDir = "~/logs/";
    let script = "";
    let params = selectedParams;

    let env = environment;

    if (env.trim().length > 0 && env.trim() !== "All") {
      // skip if no value is selected for env drop down
      env = "-env=" + env.trim();
      if (params != null && params.length > 0) {
        params.unshift(env);
      } else {
        params = [];
        params.push(env);
      }
    }

    const taskObj = taskTypes.find((item) => item.taskName === selectedProcess);
    if (taskObj) {
      cmdDir = taskObj.cmdDir;
      script = taskObj.script;

      if (params === null || params.length === 0) {
        params = taskObj.params;
      }
    }

    let finalData = {
      type: taskType,
      cmd: {
        cmdDir: cmdDir,
        outDir: outDir,
        script: script,
        params: params,
      },
    };

    const resourcePath = "integrations/eds/remoteexec";

    API.RunRedHatProcess(resourcePath, finalData).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Successful!", "Task Initiated", 10000);
        fetchRemoteExecEvents();
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
        reset();
      }
    });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    onSubmit();
    setOpen(false);
  };

  const [selectedProcess, setSelectedProcess] = useState("");
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedParams, setSelectedParams] = useState([]);
  const [environment, setEnvironment] = useState([]);

  useEffect(() => {
    if (selectedProcess) {
      const taskObj = taskTypes.find(
        (item) => item.taskName === selectedProcess
      );
      if (taskObj) {
        const params = taskObj.params;
        const tokens = params.split(" ");
        setAutocompleteOptions(tokens || []);
      }
    } else {
      setAutocompleteOptions([]);
    }
    setEnvironment("");
    // eslint-disable-next-line
  }, [selectedProcess]);

  const handleProcessChange = (event) => {
    setSelectedProcess(event.target.value);
    setSelectedParams([]);
  };

  const handleEnvChange = (event) => {
    setEnvironment(event.target.value);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedParams(newValue);
  };

  return (
    <div>
      <></>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{
          density: "compact",
          sorting: [
            {
              id: "CREATED_TS", //sort by CREATED_TS by default on page load
              desc: true,
            },
          ],
        }}
        renderTopToolbarCustomActions={() => (
          <form>
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              sx={{ ml: 3 }}
            >
              <FormControl>
                <InputLabel id="category-select-label">Process</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={selectedProcess}
                  label="Category"
                  onChange={handleProcessChange}
                  sx={{
                    paddingLeft: "10px",
                    paddingRight: "15px",
                    width: "200px",
                    //paddingTop: "12px",
                  }}
                >
                  {processTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="environment">Environment</InputLabel>
                <Select
                  labelId="environment"
                  id="environment"
                  value={environment}
                  label="environment"
                  onChange={handleEnvChange}
                  sx={{
                    paddingLeft: "10px",
                    paddingRight: "15px",
                    width: "200px",
                    //paddingTop: "12px",
                  }}
                >
                  {environments.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Autocomplete
                multiple
                options={autocompleteOptions}
                value={selectedParams}
                freeSolo
                onChange={handleAutocompleteChange}
                label="Arguments"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Arguments"
                    placeholder="Start typing..."
                    margin="normal"
                    variant="outlined"
                    sx={{
                      paddingLeft: "10px",
                      paddingRight: "15px",
                      width: "700px",
                      //paddingTop: "8px",
                    }}
                  />
                )}
                disabled={!selectedProcess}
              />

              <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                  size: "small",
                  height: "40px",
                  top: "8px",
                  left: "20px",
                }}
              >
                {"EXECUTE"}
              </Button>
            </Stack>

            <Confirm
              isOpen={open}
              title={``}
              content="Are you sure you want to run the command?"
              onConfirm={handleConfirm}
              onClose={handleDialogClose}
              cancel={`Cancel`}
              confirm={`Confirm`}
            />
          </form>
        )}
        autoResetPageIndex={false}
        enableFilters={false}
        //enableSorting={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableColumnActions={false}
        enablePagination={false}
        enableBottomToolbar={false}
      />
    </div>
  );
};

export default RedHatProcessViewTable;
