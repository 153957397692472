import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDataView } from "../../../contexts/data.view.context";

let navigation = [
  {
    label: "File Transfer",
    to: `/FileTransferHistoryView`,
  },
  /*
  {
    label: "Oura Ring",
    to: `/OuraPatients`,
  },
  **/
  {
    label: "Admin",
    to: `/Admin`,
  },
  {
    label: "Config",
    to: `/Config`,
  },
  {
    label: "Reports",
    to: `/Reports`,
  },
];

let adminTabs = [
  {
    label: "Caches",
    to: `/EICaches`,
  },
  {
    label: "Remarque",
    to: `/RemarqueEvents`,
  },
  {
    label: "Query",
    to: `/QueryInfo`,
  },
  {
    label: "RedHat",
    to: `/RedHatProcess`,
  },
];

let ouraTabs = [
  {
    label: "Patients",
    to: `/OuraPatients`,
  },
  {
    label: "Add",
    to: `/AddOuraPatient`,
  },
];

let configTabs = [
  {
    label: "Connections",
    to: `/EIConnection`,
  },
  {
    label: "File",
    to: `/FileTransfers`,
  },
  {
    label: "API Configs",
    to: `/APIConfigs`,
  },
  {
    label: "Service Configs",
    to: `/ServiceConfigs`,
  },
  {
    label: "TASK SCHEDULES",
    to: `/TaskSchedules`,
  },
];

let fileTabs = [
  {
    label: "File Transfer History",
    to: `/FileTransferHistoryView`,
  },
  {
    label: "Stats by Transfer",
    to: `/FileTrnsStatsViewByTrnsID`,
  },
  {
    label: "Stats by Project",
    to: `/FileTrnsStatsViewByProject`,
  },
  {
    label: "Stats by Group",
    to: `/FileTrnsStatsViewByGroup`,
  },
];

let reportTabs = [
  {
    label: "Error Log",
    to: `/ErrorLogReport`,
  },
  {
    label: "Events",
    to: `/EventsReport`,
  },
  {
    label: "Receive Data",
    to: `/RecieveDataReport`,
  },
];

const Navigation = ({ isSignedIn }) => {
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const email = userInfo.email;
  const domain = email.split("@")[1];

  const incyte_user = userInfo.groups.find(
    (group) => group === "OURA_INCY216559"
  );

  const navigate = useNavigate();
  let subTabsItems = [];
  const { navigationIndex, setNavigationIndex } = useDataView();

  if (navigationIndex.main === 0) {
    subTabsItems = fileTabs;
  }

  if (navigationIndex.main === 1) {
    subTabsItems = ouraTabs;
  }

  if (navigationIndex.main === 2) {
    subTabsItems = adminTabs;
  }

  if (navigationIndex.main === 3) {
    subTabsItems = configTabs;
  }

  if (navigationIndex.main === 4) {
    subTabsItems = reportTabs;
  }

  const [subMenuTabs, setSubMenuTabs] = React.useState(subTabsItems);

  if (incyte_user) {
    navigation = [
      {
        label: "Oura Ring",
        to: `/OuraPatients`,
      },
    ];
  }

  if (domain === "remarquesystems.com") {
    navigation = [
      {
        label: "File Transfer History",
        to: `/FileTransferHistoryView`,
      },
    ];
  }

  const handleChange = (ev, index) => {
    let location = navigation[index].to;

    let defaultNavigationIndex = {
      main: index,
      sub: 0,
    };

    sessionStorage.setItem("mainNavIndex", index);
    sessionStorage.setItem("subNavIndex", 0);

    if (location === "/Admin") {
      setSubMenuTabs(adminTabs);
      location = "/EICaches";
    } else if (location === "/FileTransferHistoryView") {
      setSubMenuTabs(fileTabs);
    } else if (location === "/OuraPatients") {
      setSubMenuTabs(ouraTabs);
    } else if (location === "/Config") {
      setSubMenuTabs(configTabs);
      location = "/EIConnection";
    } else if (location === "/Reports") {
      setSubMenuTabs(reportTabs);
      location = "/ErrorLogReport";
    }

    setNavigationIndex(defaultNavigationIndex);
    navigate(location);
  };

  const handleSubTabsChange = (ev, index) => {
    const location = subMenuTabs[index].to;

    let defaultNavigationIndex = {
      main: navigationIndex.main,
      sub: index,
    };

    sessionStorage.setItem("subNavIndex", index);
    setNavigationIndex(defaultNavigationIndex);
    navigate(location);
  };

  return (
    <Container>
      <Tabs
        //value={currentNavIndex}
        value={navigationIndex.main}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        {navigation.map((nav) => {
          return (
            <Tab
              key={nav.to}
              label={nav.label}
              style={{
                color: "#FFFFFF",
              }}
            />
          );
        })}
      </Tabs>

      <Tabs
        value={navigationIndex.sub}
        onChange={handleSubTabsChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#70db70",
          },
        }}
      >
        {subMenuTabs.map((nav) => {
          return (
            <Tab
              key={nav.to}
              label={nav.label}
              style={{
                color: "#70db70",
              }}
            />
          );
        })}
      </Tabs>
    </Container>
  );
};

export default Navigation;

const Container = styled.div`
  margin: 0 5em;
`;
